import './index.scss'


$(document).ready(function(){
  // $('.article-reply .reply-btn').on('click',function(){
  //   let username = $(this).closest('.title').find('.info .username').html()
  //   $('.reply-form .replyto').show().find('.username').html(username)
  //   $('#reply-message').focus()
  // })
  // $('#reply-message').blur(function(){
  //   $('.reply-form .replyto').hide()
  // })
  
})