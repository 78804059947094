module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="ny-banner bg-cover" style="background-image: url(' +
((__t = (require('../../assets/images/ny_banner.jpg'))) == null ? '' : __t) +
');"></section>';

}
return __p
}