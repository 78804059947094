module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer class="container">\r\n  <div class="footer">\r\n    <div class="footer-info">\r\n      <div class="foot-contact">\r\n        <div class="footer-title">联系我们</div>\r\n        <div>客服电话：<a href="tel:+18066009042">18066009042</a></div>\r\n        <div><span class="hide">客服电话：</span><a href="tel:+19951166889">19951166889</a></div>\r\n        <div>邮箱：<a href="mailto:911613492@qq.com">911613492@qq.com</a></div>\r\n      </div>\r\n      <div class="foot-link">\r\n        <div class="footer-title">相关链接</div>\r\n        <div><a href="#" target="_blank">政府招商引资项目精细化管理系统</a></div>\r\n        <div><a href="#" target="_blank">在政府AI产业大数据招商引资系统</a></div>\r\n      </div>\r\n      <div class="foot-ewm">\r\n        <div class="footer-title">了解更多</div>\r\n        <div class="ewm">\r\n          <a href="' +
((__t = (require('../../assets/images/wx-code.jpg'))) == null ? '' : __t) +
'" target="_blank">\r\n            <img src="' +
((__t = (require('../../assets/images/wx-code.jpg'))) == null ? '' : __t) +
'" alt="">\r\n          </a>\r\n          <p>小程序</p>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class="copyright">\r\n      <div class="t1">\r\n        社会信用代码911111111111111111111M<span>丨</span>\r\n        <a href="#">用户协议</a><span>丨</span>\r\n        <a href="#">隐私政策</a><span>丨</span>\r\n        <a href="#">知识产权声明</a><span>丨</span>\r\n        <a href="#">备案信息11231546421324</a>\r\n      </div>\r\n      <div class="t2"><a href="#">青岛乾文文化传播有限公司</a> Copyright <script language="JavaScript">var today = new Date();document.write(today.getFullYear())</script> All rights reserved</div>\r\n    </div>\r\n  </div>\r\n</footer>';

}
return __p
}