import './index.scss'

$('[data-open="cmenu"]').on('click', function () {
  if($('header .hamburger').hasClass('active')){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
  }else{
    $('header .hamburger').addClass('active')
    $('header .mob-header').addClass('show')
    $('header .menu-mask').fadeIn(200)
  }
})

$('.mob-header ul li .sub-nav a').on('click',function(){
  setTimeout(function(){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
  },200)
})

$('.mob-header ul li .nav-title .iconfont').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

$('#header-search-open').on('click',function(){
  $('.header-search').addClass('active')
  $('#header-search-input').focus()
})
$('.header-search .header-search-close').on('click',function(){
  $('.header-search').removeClass('active')
  $('#header-search-input').blur()
})

$(document).ready(function(){
  $('.mob-header ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.nav-title .iconfont').css('display','flex')
    }
  })

  $('.header .left .nav ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.nav-title .iconfont').css('display','flex')
    }
  })

  //弹窗效果
  $('[data-open="login"]').on('click',function(){
    $('.common-dialog').hide()
    $('.mask').fadeIn(200)
    $('.common-dialog.login').fadeIn(200)
  })
  $('.mask').on('click',function(){
    $('.mask').fadeOut(200)
    $('.common-dialog').fadeOut(200)
  })
  $('.common-dialog .dialog-close').on('click',function(e){
    e.preventDefault()
    $('.mask').fadeOut(200)
    $('.common-dialog').fadeOut(200)
    $('.common-dialog textarea').val('')
  })

  //登录表单效果
  $('[name="show-pwd"]').change(function(){
    if(this.checked){
      $('[name="pwd"]').attr('type','text')
    }else{
      $('[name="pwd"]').attr('type','password')
    }
  })
  // $('.form-item .btn.send-code').on('click',function(){
  //   let _this = this
  //   let number = 60
  //   $(_this).removeClass('send-code')
  //   let timer = setInterval(function(){
  //     if(number <= 1){
  //       clearInterval(timer)
  //       $(_this).addClass('send-code')
  //     }else{
  //       number = number - 1
  //       $(_this).find('.num span').html(number)
  //     }
  //   },1000)
  // })
  $('[data-show="pwd"]').on('click',function(){
    $('.login .login-form .code-form').hide()
    $('.login .login-form .pwd-form').show()
  })
  $('[data-show="code"]').on('click',function(){
    $('.login .login-form .pwd-form').hide()
    $('.login .login-form .code-form').show()
  })

  // 笔记弹窗
  // $('[data-open="note"]').on('click',function(){
  //   $('.common-dialog').hide()
  //   $('.mask').fadeIn(200)
  //   $('.common-dialog.note').fadeIn(200)
  // })
  // 回复评论弹窗
  // $('[data-open="reply"]').on('click',function(){
  //   let username = $(this).closest('.title').find('.info .username').html()
  //   $('.common-dialog').hide()
  //   $('.mask').fadeIn(200)
  //   $('.common-dialog.reply').fadeIn(200).find('.reply-name').html(username)
  // })

})

$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  // if(scroH > 100){
  //   $('.header,.header-search').addClass('scroll')
  // }else{
  //   $('.header,.header-search').removeClass('scroll')
  // }
});

// 20240829
let hoverTimeout;  

$('.sub-nav .nav-down-left .n-d-l-it').hover(  
  // 鼠标进入  
  function () {  
    clearTimeout(hoverTimeout); // 清除上一次的计时器  
    const $this = $(this);  
    
    hoverTimeout = setTimeout(() => {  
      $this.addClass('active').siblings('.n-d-l-it').removeClass('active');  
      $this.parent('.nav-down-left').siblings('.nav-down-right').find('.n-d-r-it').eq($this.index()).addClass('show')  
      .siblings('.n-d-r-it').removeClass('show');  
    }, 200); 
  },  
  // 鼠标离开  
  function () {  
    clearTimeout(hoverTimeout); // 鼠标离开时清除计时器  
  }  
);  

let hoverliTimeout;  
let leaveTimeout; // 新增离开计时器  

$('header .header .nav ul li').hover(  
  // 鼠标进入  
  function () {  
    clearTimeout(leaveTimeout); // 清除离开的计时器  
    const $this = $(this);  

    hoverliTimeout = setTimeout(() => {  
      $this.addClass('JshoverLi').siblings('li').removeClass('JshoverLi');  
    }, 300); // 300毫秒延时  
  },  
  // 鼠标离开  
  function () {  
    clearTimeout(hoverliTimeout); // 清除悬停的计时器  
    const $this = $(this);  

    leaveTimeout = setTimeout(() => {  
      $this.removeClass('JshoverLi'); // 删除类 JshoverLi  
      $this.siblings('li').removeClass('JshoverLi'); // 确保兄弟元素也移除 JshoverLi  
    }, 300); // 300毫秒延时  
  }  
);  