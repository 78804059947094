
// import {WOW} from '../../../node_modules/wowjs/dist/wow';
// new WOW().init();

$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if($('.nav-container').length > 0){
    let fixedTop = $('.nav-container').offset().top - 150
    let fixedBtm = $('.nav-container').offset().top + $('.nav-container').outerHeight() - $('.nav-container .ny-nav').outerHeight() - 150

    if($(window).width() > 1024){
      if (scroH < fixedTop) {
        $('.nav-container .ny-nav').removeClass('fixed')
      }else if (scroH > fixedTop && scroH < fixedBtm) {
        $('.nav-container .ny-nav').addClass('fixed').removeClass('abs')
      }else{
        $('.nav-container .ny-nav').addClass('abs').removeClass('fixed')
      }
    }
  }
});